<template>
  <div id="school-fees" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-right">
        {{ sessionTitle }}
      </p>
      <b-table :data="schoolFees" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ schoolFees.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="School Class" v-slot="props">
          {{ props.row.school_class_name }}
        </b-table-column>

        <b-table-column label="Number Of Students" v-slot="props">{{
          props.row.number_of_students
        }}</b-table-column>

        <b-table-column label="Full" v-slot="props">{{
          props.row.full
        }}</b-table-column>

        <b-table-column label="Part" v-slot="props">{{
          props.row.part
        }}</b-table-column>

        <b-table-column label="Owing" v-slot="props">{{
          props.row.owing
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            :show-update="false"
            :show-delete="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  name: 'SchoolFees',
  data() {
    return {
      schoolId: null,
      termId: null,
      pageTitle: 'School Fees',
      schoolFees: [],
      isPaginated: false,
      sessionTitle: '',
    }
  },
  methods: {
    initializeInfo(school_fee) {
      this.$router.push(
        `/school/${this.schoolId}/school_fee_info/${school_fee.school_class_id}`
      )
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.$route.params.school_id}/fee_management`,
      },
      {
        name: 'New School Fee',
        route: `/school/${this.$route.params.school_id}/school_fee/new`,
      },
      {
        name: 'School Fees Report',
        route: `/school/${this.schoolId}/school_fees_report`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.sessionTitle = term.session_title

      this.$apollo.addSmartQuery('schoolFees', {
        query: gql`
          query SchoolFeesQuery($schoolId: ID!, $termId: ID!) {
            schoolFees(schoolId: $schoolId, termId: $termId)
          }
        `,
        error(error) {
          console.log(error.message)
        },
        variables: {
          schoolId: this.schoolId,
          termId: this.termId,
        },
      })

      this.$apollo.queries.schoolFees.refetch()
    })
  },
}
</script>
